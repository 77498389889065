<template>
  <div>
    <!-- Search criteria, Intent Leads list -->
    <vx-card v-if="campaignOptions.length > 0">
      <div class="vx-row find-people">
        <!-- Search by criteria -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <!-- Search Criteria -->
          <div class="px-0 lead-accordion z-0 search-filters">
            <div class="filter-card border-0">
              <div slot="header" class="filter-header">Search Criteria</div>
              <div class="filter-list label-mt-2">
                <div class="filter-card">
                  <label>Campaigns</label>
                  <div class="my-0">
                    <v-select
                      class="multiple-select type-multiselect"
                      label="Campaign_Name"
                      :options="campaignOptions"
                      v-model="campaign"
                      :reduce="(option) => option.Id"
                      @input="removeErrorMessage"
                      multiple
                    />
                    <span
                      class="text-danger float-none text-sm error-msg"
                      v-show="campaignErrorMessage"
                      >{{ campaignErrorMessage }}</span
                    >
                  </div>
                </div>
                <div class="filter-card mb-0">
                  <div class="my-3 left-side-datepicker">
                    <label>Date</label>
                    <date-range-picker
                      class="float-none"
                      :fromDateValue="fromDate"
                      :toDateValue="toDate"
                      @inputFromDate="
                        (newFromDate) => {
                          fromDate = newFromDate;
                        }
                      "
                      @inputToDate="
                        (newToDate) => {
                          toDate = newToDate;
                        }
                      "
                    ></date-range-picker>
                  </div>
                </div>
                <!-- company, Location -->
                <vs-collapse accordion class="p-0">
                  <!-- company -->
                  <div class="filter-card mb-0">
                    <vs-collapse-item>
                      <div slot="header">Company</div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showCompanyNameText" @click="showCompanyNameText == true ? (companyName = []) : false">Company Name</vs-checkbox>
                        <div class="m-3" v-if="showCompanyNameText">
                          <input-tag v-model="companyName" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(companyName,'companyName')"></input-tag>
                        </div>
                        <vs-checkbox v-model="showCompanyWebAddressText" @click="showCompanyWebAddressText == true ? (companyWebAddress = []) : false">Company Web Address</vs-checkbox>
                        <div class="m-3" v-if="showCompanyWebAddressText">
                          <input-tag v-model="companyWebAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(companyWebAddress,'companyWebAddress')"></input-tag>
                        </div>
                        <vs-checkbox v-model="showCompanyIndustryOptions" @click="companyIndustry = []">Company Industry</vs-checkbox>
                        <div class="m-3" v-if="showCompanyIndustryOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            label="Value"
                            :options="companyIndustryOptions"
                            v-model="companyIndustry"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanyEmployeeSizeOptions" @click="companyEmployeeSize = []">Company Employee Size</vs-checkbox>
                        <div class="m-3" v-if="showCompanyEmployeeSizeOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            label="Value"
                            :options="companyEmployeeSizeOptions"
                            v-model="companyEmployeeSize"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanyRevenueBandsOptions" @click="companyRevenueBands = []">Company Revenue Band</vs-checkbox>
                        <div class="m-3" v-if="showCompanyRevenueBandsOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            label="Value"
                            :options="companyRevenueBandsOptions"
                            v-model="companyRevenueBands"
                            multiple
                          />
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div>
                  <!-- Location -->
                  <div class="filter-card">
                    <vs-collapse-item>
                      <div slot="header">Location</div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showContactZipCode" @click="contactZipCode = []">Zip</vs-checkbox>
                        <div v-if="showContactZipCode">
                          <input-tag v-model="contactZipCode" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactZipCode,'contactZipCode')"></input-tag>
                        </div>
                        <vs-checkbox v-model="showContactCity" @click="contactCity = []">City</vs-checkbox>
                        <div v-if="showContactCity">
                          <input-tag v-model="contactCity" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactCity,'contactCity')"></input-tag>
                        </div>
                        <vs-checkbox v-model="showContactSatesOptions" @click="contactStates = []">State</vs-checkbox>
                        <div v-if="showContactSatesOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="contactStatesOptions"
                            v-model="contactStates"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showContactCountriesOptions" @click="contactCountries = []">Country</vs-checkbox>
                        <div v-if="showContactCountriesOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="contactCountriesOptions"
                            v-model="contactCountries"
                            multiple
                          />
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div>
                </vs-collapse>
              </div>
            </div>
          </div>
          <!-- buttons -->
          <div class="pt-5 mt-5 flex">
            <vs-button
              title="Update"
              color="primary"
              type="filled"
              class="mr-1 px-5"
              @click="getLeads()"
              >{{ LabelConstant.buttonLabelSearch }}</vs-button
            >
          </div>
        </div>
        <!-- Intent leads table-->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div class="card-title pb-0 mb-3 more-card-title flex align-center">
            <div><h2>Companies Showing the Most Interest</h2></div>
            <div>
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
                class="mr-2 float-left"
                @click="exportCompanies()"
                >{{ LabelConstant.buttonLabelExport }}
              </vs-button>
            </div>
          </div>
          <div class="table-row-select lead-select pl-0 mb-3">
            <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
            </vs-select>
          </div>
          <div class="common-table search-row pt-0">
            <!-- Intent Leads table -->
            <vs-table
              ref="tableData"
              multiple
              v-model="selectedMain"
              :data="leads"
              :max-items="perPage"
              pagination
              search
            >
              <template slot="thead">
                <vs-th>Logo</vs-th>
                <vs-th sort-key="CompanyName">Company Name</vs-th>
                <vs-th sort-key="location">Location</vs-th>
                <vs-th sort-key="sortableEmployees">Employees</vs-th>
                <vs-th sort-key="RevenueRange">Revenue</vs-th>
                <vs-th sort-key="Industry">Industry</vs-th>
                <vs-th sort-key="Trigger_Value">Keywords</vs-th>
                <vs-th sort-key="Score">Score</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].CompanyName">
                    <div class="flex items-center">
                      <a @click="companyDetailPopup = true, selectedCompanyId=data[indextr].CompanyId, getCompanyDetail(data[indextr].CompanyId), getIntentSignals(data[indextr].CompanyId), companyDetailsScore = data[indextr].Score">
                        <img :src="data[indextr].Logo" alt="Company Logo" class="inline-block tbl-img mr-2" />
                      </a>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].CompanyName">
                    <a @click="companyDetailPopup = true, selectedCompanyId=data[indextr].CompanyId, getCompanyDetail(data[indextr].CompanyId), getIntentSignals(data[indextr].CompanyId), companyDetailsScore = data[indextr].Score">
                      {{ data[indextr].CompanyName }}
                    </a>
                  </vs-td>
                  <vs-td :data="data[indextr].location">
                    {{ data[indextr].location }}
                  </vs-td>
                  <vs-td :data="data[indextr].sortableEmployees">
                    {{ data[indextr].Employees | tableRecordNumberFormatter }}
                  </vs-td>
                  <vs-td :data="data[indextr].RevenueRange">
                    {{ data[indextr].RevenueRange }}
                  </vs-td>
                  <vs-td :data="data[indextr].Industry">
                    {{ data[indextr].Industry }}
                  </vs-td>
                  <vs-td :data="data[indextr].Trigger_Value">
                    <span v-show="leadsMoreOrLessArr[indextr] == 'less'">{{ data[indextr].Trigger_Value | commaSeparated("less") }}</span>
                    <span v-show="leadsMoreOrLessArr[indextr] == 'less' && data[indextr].showMoreLessIcon" class="more-icon" @click="changeKeywordsMoreLess('more', indextr)">
                      <feather-icon
                        icon="InfoIcon"
                        title="View More"
                      ></feather-icon>
                    </span>
                    <span v-show="leadsMoreOrLessArr[indextr] == 'more'">{{ data[indextr].Trigger_Value | commaSeparated("more") }}</span>
                    <span v-show="leadsMoreOrLessArr[indextr] == 'more' && data[indextr].showMoreLessIcon" class="more-icon" @click="changeKeywordsMoreLess('less', indextr)">
                      <feather-icon
                        icon="ArrowLeftCircleIcon"
                        title="View Less"
                      ></feather-icon>
                    </span>
                  </vs-td>
                  <vs-td :data="data[indextr].Score">
                    {{ data[indextr].Score }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- Total Companies -->
            <p class="table-data-entries-details">
              Total Companies: <span>{{ leads.length }}</span>
            </p>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- if campaing not available  selected -->
    <vx-card v-else>
      <div class="vx-row">
        <div class="vx-col w-full text-center">
          <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto">
            <img
              src="../../../src/assets/images/maintance.png"
              class="d-inline-block w-full"
            />
            <router-link :to="{ name: 'IntentCampaigns' }">
              <h4 class="text-primary py-5">
                Please configure a campaign to get started
              </h4>
            </router-link>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- end if campaing not available  selected -->
    
    <!-- Select Connection -->
    <find-any-one-select-connection 
      :popupTitle="'Select Connection'" 
      :connectionPopup="connectionPopup" 
      :pushModule="pushModule" 
      :selectedMain="selectedMain" 
      :selectedInner="selectedInner" 
      :applicationId="4"  
      @cancelConnection="(connectionPopup=false,selectedInner = [],selectedMain=[],pushModule=null)" 
    ></find-any-one-select-connection>
   
    <!-- Export Contact options modal (after selecting contacts and clicking on Export button this will open) -->
    <find-any-one-export-options-modal
      :popupTitle="'Download Contacts'"
      :isPopup="openExportContactOptionsPopup"
      @openExportContactOptionsPopupInput="
        (newOpenExportContactOptionsPopup) => {
          openExportContactOptionsPopup = newOpenExportContactOptionsPopup;
        }
      "
      @exportContactTypeInput="
        (newExportContactType) => {
          exportContactType = newExportContactType;
        }
      "
      @clickForGetContactLimits="getContactLimits()"
      @contactpushTocrm="(openExportContactOptionsPopup = false,connectionPopup = true,pushModule = 'contact')"
    ></find-any-one-export-options-modal>

    <!-- Download Excel contacts (After getting export contacts use can download CSV or EXCEL) -->
    <find-any-one-download-final
      :popupTitle="'Download Contacts'"
      :isPopup="downloadExcelContactPopup"
      :sheetOptions="downloadContacts"
      :excelFileName="'Contacts.xlsx'"
      :excelOptions="{ bookType: 'xlsx' }"
      :csvFileName="'Contacts.csv'"
      :csvOptions="{ bookType: 'csv' }"
      :whichOptionShow="exportContactType"
      @clickForFinish="(downloadExcelContactPopup = false), (exportContactType = null)"
      @downloadPushTOCRM="(downloadExcelContactPopup = false,connectionPopup = true,pushModule = 'contact')"
      :showPushtoCRM="true"
    ></find-any-one-download-final>

    <!-- Credit warning popup (If there are enough contact limit then this will open) -->
    <find-any-one-credit-warning-modal
      :popupTitle="'Download Contacts'"
      :isPopup="creditWarningPopup"
      :isShowCreditDeduction="isCreditDeductionEnable"
      :creditDeductionMessage="numberOfCreditMessage"
      :isCompanyDetailPopup="companyDetailPopup"
      :isSelectedMainLength="selectedMain.length > 0"
      :selectedContactId="selectedContactId"
      :exportContactType="exportContactType"
      :isSelectedInnerLength="selectedInner.length > 0"
      @getContactDetail="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,tagModuleRequest='contactdetails',addTagToContacts(tags = {contactSelectedTags:[]})"
      @exportContacts="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,listContactSavedTags(),tagModuleRequest='export',addTagsPopup = true"
      @clickForFinish="
        (creditWarningPopup = false),
          (numberOfCreditMessage = null),
          (isCreditDeductionEnable = false),
          (selectedMain = []),
          (exportContactType = null),
          (selectedContactId = null),
          (selectedInner = [])
      "
    ></find-any-one-credit-warning-modal>

    <!-- Add Update tag popup -->
    <find-any-one-add-update-tag-modal
      ref="addUpdateTag"
      :isAddUpdateTagPopupActive="addUpdateTagPopup"
      @saveNewTag="saveUpdateTag"
      @closeAddUpdateTagPopup="closeAddUpdateTagPopup"
      :tagDetails="userDefinedTagDetails"
    ></find-any-one-add-update-tag-modal>
    
    <!-- Add  tag To contactPopup -->
    <find-any-one-add-tag-to-selected-modal
      ref="addTagToSelectedContact"
      :isAddTagsPopupActive="addTagsPopup"
      :userDefinedTagsOptions="selectTagsOptions"
      @closePopupAddTagToSelected="addTagsPopup = false"
      @addTagToContacts="addTagToContacts"
      @createNewTag="addUpdateTagPopup = true"
      :requestFromModule="tagModuleRequest"
      @updateTagToContacts="updateTagsContactDetails"
      @removeTagToContacts="removeTagsFromContactConfirmation"
    ></find-any-one-add-tag-to-selected-modal>
        
    <!-- remove tag -->
    <find-any-one-remove-tag-modal
      :isRemoveTagPopupActive ="removeTagPopup"
      @clickCloseRemoveTagPopup="removeTagPopup = false,companyDetailPopup?selectedInner=[]:selectedMain=[]"
      @removeTagsFromContact="removeTagsFromContact"
    ></find-any-one-remove-tag-modal>

    <!-- manage tag -->
    <find-any-one-manage-tag-modal
      :isManageTagsPopupActive="manageTagsPopup"
      :userDefinedTagsList="selectTagsOptions"
      @closeManageTagPopup="manageTagsPopup = false"
      @clickOnDeleteTag="deleteUserDefinedTag"
      @createNewTag="addUpdateTagPopup = true"
      @editUserDefinedTag="editUserDefinedTag"
    ></find-any-one-manage-tag-modal>

    <!-- Contacts details modal -->
    <find-any-one-contact-details-modal
      :contactsDetailPopup="contactsDetailPopup"
      @clickForClose="closeContactDetailsPopup()"
      :contactDetails="contactDetails"
      @editTagsForContact="editTagsForContact()"
      moduleName="leadIntent"
    ></find-any-one-contact-details-modal>

    <!-- credit limit warning popup (If there are no enough contact limit then this will open) -->
    <find-any-one-credit-limit-warning-modal
      :popupTitle="'Download Contacts'"
      :isPopup="creditLimitWarningPopup"
      @clickForFinish="creditLimitWarningPopup = false"
    ></find-any-one-credit-limit-warning-modal>

    <!-- Select contact warning for add to my saved -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="'Add Contacts'"
      :isPopup="selecteContactWarningForAddToMySavedPopup"
      :selectContactWarningMessage="
        selecteContactWarningForAddToMySavedPopupMessage
      "
      @clickForFinish="selecteContactWarningForAddToMySavedPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>

    <!-- Credit warning for add to my saved -->
    <find-any-one-credit-warning-add-to-saved-modal
      :popupTitle="'Add Contacts'"
      :isPopup="openAddToMySavedContactsCreditPopup"
      :creditWarningMessage="openAddToMySavedContactsCreditPopupMessage"
      @clickForYes="getContactLimitsForAddToMySavedContacts()"
      @clickForNo="cancelAddToMySavedContacts()"
    ></find-any-one-credit-warning-add-to-saved-modal>

    <!-- Success massage for add to my saved -->
    <find-any-one-success-message-add-to-saved-modal
      :popupTitle="'Add Contacts'"
      :isPopup="addToMySavedContactsSuccessPopup"
      @clickForFinish="addToMySavedContactsSuccessPopup = false"
    ></find-any-one-success-message-add-to-saved-modal>

    <!-- Company details modal -->
    <find-any-one-company-details-modal
      ref="searchContactsRef"
      :companyDetailPopup="companyDetailPopup"
      @clickForClose="closeCompanyDetailPopup()"
      :companyDetail="companyDetail"
      :showEntriesOptions="showEntriesOptions"
      :recordPerInnerPageValue="recordPerInnerPage"
      @recordPerInnerPageInput="(newRecordPerInnerPage) => {recordPerInnerPage = newRecordPerInnerPage;}"
      @clickForExport="getContactLimits"
        @exportType="(newExportType) => {
        exportContactType = newExportType;
      }"
      :isShowAddToMySavedContactsButton="true"
      :totalContactLimitRemaining="totalContactLimitRemaining"
      :disabledExportORAddToMySavedContactsORDownload="totalContactLimitRemaining <= 0"
      @clickForAddToMySavedContacts="addToMySavedContacts()"
      :selectedInnerValue="selectedInner"
      @selectedInnerInput="(newSelectedInner) => {selectedInner = newSelectedInner;}"
      @contactsDetailPopupInput="(newContactsDetailPopup) => {
        contactsDetailPopup = newContactsDetailPopup;
        tagModuleRequest = 'update';
      }"
      @selectedContactIdInput="(newSelectedContactId) => {selectedContactId = newSelectedContactId;}"
      @clickForGetSavedContactDetail="getSavedContactDetail()"
      @clickForOpenContactCreditPopup="openContactCreditPopup()"
      :companyTotalContacts="companyTotalContacts"
      :companyTotalPages="companyTotalPages"
      @clickForGetCompanyDetailByPagination="getCompanyDetailByPagination"
      :companyDetailsScore="companyDetailsScore"
      :jobLevelOptions="jobLevelOptions"
      :jobFunctionOptions="jobFunctionOptions"
      :jobStartDateOptions="jobStartDateOptions"
      :contactRegionOptions="contactRegionOptions"
      :contactCountriesOptions="contactCountriesOptions"
      :contactStatesOptions="contactStatesOptions"
      :personalContactGenderOptions="personalContactGenderOptions"
      :isAvailableContactsIntentSignal="true"
      :intentSignals="intentSignals"
      :selectedCompanyId="selectedCompanyId"
      @addTagToSelected="addTagToSeleted(),tagModuleRequest = 'add'"
      @removeTagToSelected="removeTagSelected(),tagModuleRequest='remove'"
      @manageTags="manageTagsPopup = true,tagModuleRequest='add', listContactSavedTags()"
    ></find-any-one-company-details-modal>

    <!-- Select companies warning for export companies -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="'Download Companies'"
      :isPopup="selecteCompaniesWarningForExportCompaniesPopup"
      :selectContactWarningMessage="'Please select companies to export.'"
      @clickForFinish="selecteCompaniesWarningForExportCompaniesPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>

    <!-- Download Excel Companies -->
    <find-any-one-download-final
      :popupTitle="'Download Companies'"
      :isPopup="downloadExcelCompaniesPopup"
      :sheetOptions="downloadCompanies"
      :excelFileName="'Companies.xlsx'"
      :excelOptions="{ bookType: 'xlsx' }"
      :csvFileName="'Companies.csv'"
      :csvOptions="{ bookType: 'csv' }"
      :whichOptionShow="true"
      @clickForFinish="
        (downloadExcelCompaniesPopup = false), (selectedMain = [])
      "
      @downloadPushTOCRM="(downloadExcelCompaniesPopup = false,connectionPopup = true,pushModule = 'companies')"
      :showPushtoCRM="false"
    ></find-any-one-download-final>
    
    <!-- Download Excel -->
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadContact.data"
          v-for="downloadContact in downloadContacts"
          :key="downloadContact.name"
          :sheet-name="downloadContact.name"
        />
        <xlsx-download :filename="'Contacts.csv'" :options="{ bookType: 'csv' }">
          <a ref="selectedToCsv">{{ LabelConstant.buttonLabelExportCSV }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>

    <!-- Download Excel -->
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadContact.data"
          v-for="downloadContact in downloadContacts"
          :key="downloadContact.name"
          :sheet-name="downloadContact.name"
        />
        <xlsx-download :filename="'Contacts.xlsx'" :options="{ bookType: 'xlsx' }">
          <a ref="selectedToExcel">{{ LabelConstant.buttonLabelExportExcel }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import InputTag from "vue-input-tag";
import { BMedia, BImg, BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "../../components/filter/DateRangePicker";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
import FindAnyOneExportOptionsModal from "../../components/modal/FindAnyOneExportOptionsModal.vue";
import FindAnyOneDownloadFinal from "../../components/modal/FindAnyOneDownloadFinal.vue";
import FindAnyOneCreditWarningModal from "../../components/modal/FindAnyOneCreditWarningModal.vue";
import FindAnyOneContactDetailsModal from "../../components/modal/FindAnyOneContactDetailsModal.vue";
import FindAnyOneCompanyDetailsModal from "../../components/modal/FindAnyOneCompanyDetailsModal.vue";
import FindAnyOneCreditLimitWarningModal from "../../components/modal/FindAnyOneCreditLimitWarningModal.vue";
import FindAnyOneSelectContactAddToSavedWarningModal from "../../components/modal/FindAnyOneSelectContactAddToSavedWarningModal.vue";
import FindAnyOneCreditWarningAddToSavedModal from "../../components/modal/FindAnyOneCreditWarningAddToSavedModal.vue";
import FindAnyOneSuccessMessageAddToSavedModal from "../../components/modal/FindAnyOneSuccessMessageAddToSavedModal.vue";
import FindAnyOneSelectConnection from "../../components/modal/FindAnyOneSelectConnection.vue"
import FindAnyOneAddTagToSelectedModal from "../../components/modal/FindAnyOneAddTagToSelectedModal.vue";
import FindAnyOneManageTagModal from "../../components/modal/FindAnyOneManageTagModal.vue";
import FindAnyOneAddUpdateTagModal from "../../components/modal/FindAnyOneAddUpdateTagModal.vue";
import FindAnyOneRemoveTagModal from "../../components/modal/FIndAnyOneRemoveTagModal.vue"
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  components: {
    InputTag,
    BMedia,
    BImg,
    BCardText,
    "v-select": vSelect,
    DateRangePicker,
    FindAnyOneExportOptionsModal,
    FindAnyOneDownloadFinal,
    FindAnyOneCreditWarningModal,
    FindAnyOneContactDetailsModal,
    FindAnyOneCompanyDetailsModal,
    FindAnyOneCreditLimitWarningModal,
    FindAnyOneSelectContactAddToSavedWarningModal,
    FindAnyOneCreditWarningAddToSavedModal,
    FindAnyOneSuccessMessageAddToSavedModal,
    FindAnyOneSelectConnection,
    FindAnyOneAddTagToSelectedModal,
    FindAnyOneManageTagModal,
    FindAnyOneAddUpdateTagModal,
    FindAnyOneRemoveTagModal,
    XlsxWorkbook, XlsxSheet, XlsxDownload
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.listJobLevels();
    this.listJobFunctions();
    this.listJobStartDates();
    this.listCompanyIndustries();
    this.listCompanyEmployeeSizes();
    this.listCompanyRevenueBands();
    this.getListOfCampagins();
    this.listContactRegions();
    this.listContactCountries();
    this.listContactStates();
    this.listContactGenders();
    this.getContactLimitsOnLoad()
  },
  filters: {
    addSpaceAfterComma: function (value) {
      return value.replace(/,/g, ", ");
    },
    tableRecordNumberFormatter: function (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    lastUpdatedDate: function (value) {
      return moment(value).format("MM/DD/YYYY");
    },
    commaSeparated: function (params, moreLess) {
      var keywordsArray = params.split(",");
      var lessKeyWords = "";
      for (let i = 0; i < keywordsArray.length; i++) {
        lessKeyWords += i == keywordsArray.length ? keywordsArray[i] : ", " + keywordsArray[i];
        if (moreLess == "less") {
          if (i == 1) {
            if (keywordsArray.length > 2) {
              lessKeyWords += ",...";
            }
            break;
          }
        }
      }
      return lessKeyWords.substring(1);
    },
  },
  data: () => ({
    selectedCompanyId: null,
    //Job Criteria
    jobLevelOptions: null,
    jobFunctionOptions: null,
    jobStartDateOptions: null,

    //Location Criteria
    contactRegionOptions: null,

    //personal Criteria
    personalContactGenderOptions: null,

    downloadExcelCompaniesPopup: false,
    downloadCompanies: null,
    //Company Criteria
    campaign: [],
    campaignErrorMessage: null,
    campaignOptions: [""],

    fromDate: null,
    toDate: null,

    showCompanyNameText: false,
    companyName: [],

    showCompanyWebAddressText: false,
    companyWebAddress: [],

    showCompanyIndustryOptions: false,
    companyIndustry: [],
    companyIndustryOptions: null,

    showCompanyEmployeeSizeOptions: false,
    companyEmployeeSize: [],
    companyEmployeeSizeOptions: null,

    showCompanyRevenueBandsOptions: false,
    companyRevenueBands: [],
    companyRevenueBandsOptions: null,

    showContactZipCode: false,
    contactZipCode: [],

    showContactCity: false,
    contactCity: [],

    showContactSatesOptions: false,
    contactStates: [],
    contactStatesOptions: null,

    showContactCountriesOptions: false,
    contactCountries: [],
    contactCountriesOptions: null,

    leads: [],
    leadsMoreOrLessArr: [],
    selectedMain: [],
    perPage: 5,
    recordList: [
      {text: '5', value: 5},
      {text: '10', value: 10},
      {text: '25', value: 25},
      {text: '50', value: 50},
      {text: '100', value: 100}
    ],

    showEntriesOptions: [
      { key: 10, value: 10 },
      { key: 25, value: 25 },
      { key: 50, value: 50 },
      { key: 100, value: 100 },
      { key: 500, value: 500 },
      { key: 1000, value: 1000 },
    ],

    intentSignals: null,
    companyDetailPopup: false,
    selectedInner: [],
    companyDetail: null,
    companyDetailsScore: null,
    companySearchContactsParams: null,
    companyTotalContacts: 0,
    companyTotalPages: 0,
    recordPerInnerPage: 10,

    creditWarningPopup: false,
    contactsDetailPopup: false,
    numberOfCreditMessage: null,
    selectedContactId: null,
    contactDetails: null,
    isCreditDeductionEnable: false,

    downloadExcelContactPopup: false,
    downloadContacts: null,
    creditLimitWarningPopup: false,
    exportContactType: null,
    openExportContactOptionsPopup: false,

    totalContactLimitRemaining: 0,
    isContactDetailsPopupForSaved: false,

    selecteContactWarningForAddToMySavedPopup: false,
    selecteContactWarningForAddToMySavedPopupMessage: null,

    addabelContactsCount: 0,
    openAddToMySavedContactsCreditPopup: false,
    openAddToMySavedContactsCreditPopupMessage: null,

    addToMySavedContactsSuccessPopup: false,

    selecteCompaniesWarningForExportCompaniesPopup: false,

    connectionPopup:false,
    pushModule:null,

    addTagsPopup:false,
    addUpdateTagPopup:false,
    manageTagsPopup:false,
    removeTagPopup:false,
    selectTagsOptions:[],
    userDefinedTagDetails:null,
    tagModuleRequest:null,
    tagRemoveList:null,
  }),
  methods: {
    // comma and semicolon separated input tag
    termSeparation(values,name){
      if(values[values.length-1]){
        let singleValue=values[values.length-1]
        let splitValue=singleValue.split(/[,|;]/);
        let valueSeprated=[]
        splitValue.forEach(e => {
          if(e.trim().length){
            valueSeprated.push(e.trim());
          }
        });

        if(name=="companyName"){
          this.companyName.pop();
          this.companyName=this.companyName.concat(valueSeprated);
          this.companyName=[...new Set(this.companyName)];
        }
        if(name=="companyWebAddress"){
          this.companyWebAddress.pop();
          this.companyWebAddress=this.companyWebAddress.concat(valueSeprated);
          this.companyWebAddress=[...new Set(this.companyWebAddress)];
        }
        if(name=="contactCity"){
          this.contactCity.pop();
          this.contactCity=this.contactCity.concat(valueSeprated);
          this.contactCity=[...new Set(this.contactCity)];
        }
        if(name=="contactZipCode"){
          this.contactZipCode.pop();
          this.contactZipCode=this.contactZipCode.concat(valueSeprated);
          this.contactZipCode=[...new Set(this.contactZipCode)];
        }
      }
    },
    removeErrorMessage(value) {
      if (value.length > 0) {
        this.campaignErrorMessage = null;
      } else {
        this.campaignErrorMessage = "Please select campaign.";
      }
    },
    //Get download contacts limit to show on top button on load
    getContactLimitsOnLoad(){
      //this.$vs.loading();
      this.axios.get("/ws/FindAnyoneSearchContacts/GetContactLimits").then((response) => {
        let data = response.data;
        this.totalContactLimitRemaining = data.TotalContactLimitRemaining
       // this.$vs.loading.close();
      }).catch((e) => {
       // this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    //Get download contacts limit to check when exporting
    getContactLimits() {
      let selectedContacts = [];
      if (this.companyDetailPopup) {
        selectedContacts = this.selectedInner;
      } else {
            selectedContacts = this.selectedMain;
      }
      if(selectedContacts.length){

        this.$vs.loading();
        this.axios
          .get("/ws/FindAnyoneSearchContacts/GetContactLimits")
          .then((response) => {
            let data = response.data;
  
            if (data.DailyContactLimit == -1) {
              //this.exportContacts(selectedContacts);
              this.checkCreditDeductForExport(selectedContacts);
            } else if (
              selectedContacts.length <= data.TotalContactLimitRemaining &&
              selectedContacts.length <= data.DailyContactLimitRemaining
            ) {
              this.openContactCreditPopup();
            } else {
              this.creditLimitWarningPopup = true;
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
      } else {
         this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contact to export"
            this.selecteContactWarningForAddToMySavedPopup = true;
      }
    },
    listJobLevels() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobLevels")
        .then((response) => {
          let data = response.data;
          this.jobLevelOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listJobFunctions() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobFunctions")
        .then((response) => {
          let data = response.data;
          (this.jobFunctionOptions = data), this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listJobStartDates() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobStartDates")
        .then((response) => {
          let data = response.data;
          this.jobStartDateOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyIndustries() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyIndustries")
        .then((response) => {
          let data = response.data;
          this.companyIndustryOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyEmployeeSizes() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyEmployeeSizes")
        .then((response) => {
          let data = response.data;
          this.companyEmployeeSizeOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyRevenueBands() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyRevenueBands")
        .then((response) => {
          let data = response.data;
          this.companyRevenueBandsOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactRegions() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactRegions")
        .then((response) => {
          let data = response.data;
          this.contactRegionOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactCountries() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactCountries")
        .then((response) => {
          let data = response.data;
          this.contactCountriesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactStates() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactStates")
        .then((response) => {
          let data = response.data;
          this.contactStatesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactGenders() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactGenders")
        .then((response) => {
          let data = response.data;
          this.personalContactGenderOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get list of campaign for filter
    async getListOfCampagins() {
      this.$vs.loading();
      await this.axios
        .get("/ws/FindAnyoneCampaign/GetCampaigns")
        .then((response) => {
          let data = response.data;
          this.campaignOptions = data;
          if (this.campaignOptions.length > 0) {
            this.campaign.push(this.campaignOptions[0].Id);
            this.getLeads();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get leads based on Search criteria
    getLeads() {
      let getLeadsParams = {
        CampaignIds: this.campaign,
        StartDate: this.fromDate,
        EndDate: this.toDate,
        CompanyIndustries: this.companyIndustry,
        CompanyNames: this.companyName,
        CompanyRevenueIds: this.companyRevenueBands,
        CompanySizeIds: this.companyEmployeeSize,
        Urls: this.companyWebAddress,
        Zips: this.contactZipCode,
        Cities: this.contactCity,
        States: this.contactStates,
        Countries: this.contactCountries,
      };
      if (this.campaign.length > 0) {
        this.campaignErrorMessage = null;
        this.$refs.tableData.currentx = 1;
        this.$refs.tableData.searchx = "";
        this.$vs.loading();
        this.axios
          .post("/ws/FindAnyoneLead/getLeads", getLeadsParams)
          .then((response) => {
            let data = response.data;
            let tempLeads = data;
            let that = this;
            tempLeads.map(function (manipulatedData) {
              manipulatedData.location = that.beautifyAddress2(manipulatedData);
              manipulatedData.fullName = manipulatedData.ContactFirstName + " " + manipulatedData.ContactLastName;
              manipulatedData.Employees = parseInt(manipulatedData.Employees);
              manipulatedData.sortableEmployees = manipulatedData.Employees;
              manipulatedData.showMoreLessIcon = manipulatedData.Trigger_Value.split(",").length > 2 ? true : false;
              manipulatedData.keywordsMoreLess = "less";
              that.leadsMoreOrLessArr.push('less')
              return manipulatedData;
            });
            this.leads = tempLeads
            this.selectedMain = [];
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
      } else {
        this.campaignErrorMessage = "Please select campaign.";
      }
    },
    //Get company details
    getCompanyDetail(id) {
      this.companySearchContactsParams = {
        CompanyId: id,
        Page: 1,
        PageSize: this.recordPerInnerPage,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchContacts/GetCompanyDetail",this.companySearchContactsParams)
        .then((response) => {
          let data = response.data;
          this.companyDetail = data;
          this.companyTotalContacts = data.ContactSummary.ContactCount;
          this.companyTotalPages = Math.ceil(data.ContactSummary.ContactCount /data.ContactSummary.ContactsPerPage);
          if (this.companyTotalPages > 10000) {
            this.companyTotalPages = 10000;
          }
          let companyAddressArr = [];
          companyAddressArr.push(this.companyDetail.CompanyAddressLine1);
          companyAddressArr.push(this.companyDetail.CompanyAddressLine2);
          companyAddressArr.push(this.companyDetail.CompanyCity);
          companyAddressArr.push(this.companyDetail.CompanyRegion);
          companyAddressArr.push(this.companyDetail.CompanyCountry);
          let companyAddressFinal = "";
          for (let i = 0; i < companyAddressArr.length; i++) {
            if (companyAddressArr[i] && companyAddressArr[i].length > 0) {
              companyAddressFinal += companyAddressArr[i] + ", ";
            }
          }
          this.companyDetail.companyAddress = companyAddressFinal.substring(0,companyAddressFinal.length - 2);
          let that = this;
          this.companyDetail.ContactSummary.Contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            manipulatedData.fullName = manipulatedData.ContactFirstName + " " + manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.$refs.searchContactsRef.searchContacts();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get company details to paginate the contacts
    getCompanyDetailByPagination(pageNumber) {
      this.companySearchContactsParams.Page = pageNumber;
      this.companySearchContactsParams.PageSize = this.recordPerInnerPage;
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSavedContacts/GetCompanyDetail",this.companySearchContactsParams)
        .then((response) => {
          let data = response.data;
          this.companyDetail = data;
          this.companyTotalContacts = data.ContactSummary.ContactCount;
          this.companyTotalPages = Math.ceil(data.ContactSummary.ContactCount /data.ContactSummary.ContactsPerPage);
          if (this.companyTotalPages > 10000) {
            this.companyTotalPages = 10000;
          }
          let that = this;
          this.companyDetail.ContactSummary.Contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            manipulatedData.fullName = manipulatedData.ContactFirstName + " " + manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get Intent Signals
    getIntentSignals(id) {
      let getIntentSignalsParams = {
        CompanyId: id,
        CampaignIds: this.campaign,
        StartDate: this.fromDate,
        EndDate: this.toDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneLead/getIntentSignals", getIntentSignalsParams)
        .then((response) => {
          let data = response.data;
          this.intentSignals = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //close company details popup
    closeCompanyDetailPopup() {
      this.selectedMain = [];
      this.selectedInner = [];
      this.companyDetailPopup = false;
      this.intentSignals = null;
      this.companyDetail = null;
      this.companyDetailsScore = null;
      this.companySearchContactsParams = null;
      this.companyTotalContacts = 0;
      this.companyTotalPages = 0;
    },
    //close contact details popup
    closeContactDetailsPopup() {
      this.contactsDetailPopup = false;
      this.selectedContactId = null;
      this.contactDetails = null;
      // if (!this.isContactDetailsPopupForSaved) {
      //   if (this.companyDetailPopup) {
      //     this.$refs.searchContactsRef.searchPaginationSetTimeOut();
      //   }
      // }
      this.selectedInner = [];
      this.isContactDetailsPopupForSaved = false;
      this.getContactLimitsOnLoad()
    },
    //Open credit deduction message popup
    openContactCreditPopup() {
      let selectedContacts = [];
      if (this.selectedContactId && !this.exportContactType) {
        this.numberOfCreditMessage = "This will deduct 1 credit";
        this.isCreditDeductionEnable = true;
      } else {
        if (this.companyDetailPopup) {
          selectedContacts = this.selectedInner;
        } else {
          selectedContacts = this.selectedMain;
        }
        if (selectedContacts.length > 0) {
          this.isCreditDeductionEnable = true;
          this.numberOfCreditMessage =
            "This will deduct " + selectedContacts.length + " credits";
        } else {
          this.isCreditDeductionEnable = false;
        }
      }
      this.creditWarningPopup = true;
    },
    //Get contacts for download as CSV or EXCEL
    async exportContacts(selectedContacts) {
      let exportContactsParams = [];
      for (let i = 0; i < selectedContacts.length; i++) {
        exportContactsParams.push(selectedContacts[i].Id);
      }
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.selectedMain = [];
      this.selectedInner = [];
      this.$vs.loading();
      await this.axios
        .post("/ws/FindAnyoneSearchContacts/ExportContacts", {
          contactIds: exportContactsParams,
        })
        .then((response) => {
          let data = response.data;
          //this.downloadExcelContactPopup = true;
           setTimeout(() => {
            if(this.exportContactType == 'csv'){
                this.$refs.selectedToCsv.click();
              } else {
                this.$refs.selectedToExcel.click();
              }
            }, 500);
          this.downloadContacts = [{ name: "Contacts", data: data }];
          this.getContactLimitsOnLoad()
          this.$refs.searchContactsRef.searchPaginationSetTimeOut();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Get non saved contact details with using credit
    getContactDetail() {
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.contactsDetailPopup = true;
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/GetContactDetail?id=" +this.selectedContactId)
        .then((response) => {
          let data = response.data;
          this.contactDetails = data;
          let officeAddressArr = [];
          let contactAddressArr = [];
          officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
          officeAddressArr.push(this.contactDetails.ContactOfficeCity);
          officeAddressArr.push(this.contactDetails.ContactOfficeState);
          officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
          contactAddressArr.push(this.contactDetails.CompanyCity);
          contactAddressArr.push(this.contactDetails.CompanyRegion);
          contactAddressArr.push(this.contactDetails.CompanyCountry);
          let officeAddressFinal = "";
          let contactAddressFinal = "";
          for (let i = 0; i < officeAddressArr.length; i++) {
            if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
              officeAddressFinal += officeAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < contactAddressArr.length; i++) {
            if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
              contactAddressFinal += contactAddressArr[i] + ", ";
            }
          }
          this.contactDetails.officeAddress = officeAddressFinal.substring(0,officeAddressFinal.length - 2);
          this.contactDetails.contactAddress = contactAddressFinal.substring(0,contactAddressFinal.length - 2);
          this.listContactSavedTags();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get saved contact details with using credit
    getSavedContactDetail() {
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.contactsDetailPopup = true;
      this.isContactDetailsPopupForSaved = true;
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSavedContacts/GetSavedContactDetail?id=" +this.selectedContactId)
        .then((response) => {
          let data = response.data;
          this.contactDetails = data;
          let officeAddressArr = [];
          let contactAddressArr = [];
          officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
          officeAddressArr.push(this.contactDetails.ContactOfficeCity);
          officeAddressArr.push(this.contactDetails.ContactOfficeState);
          officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
          contactAddressArr.push(this.contactDetails.CompanyCity);
          contactAddressArr.push(this.contactDetails.CompanyRegion);
          contactAddressArr.push(this.contactDetails.CompanyCountry);
          let officeAddressFinal = "";
          let contactAddressFinal = "";
          for (let i = 0; i < officeAddressArr.length; i++) {
            if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
              officeAddressFinal += officeAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < contactAddressArr.length; i++) {
            if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
              contactAddressFinal += contactAddressArr[i] + ", ";
            }
          }
          this.contactDetails.officeAddress = officeAddressFinal.substring(0,officeAddressFinal.length - 2);
          this.contactDetails.contactAddress = contactAddressFinal.substring(0,contactAddressFinal.length - 2);
          this.listContactSavedTags();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Address beautification to show in table
    beautifyAddress(value) {
      let contactAddressArr = [];
      contactAddressArr.push(value.ContactCity);
      contactAddressArr.push(value.ContactState);
      contactAddressArr.push(value.ContactCountry);
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
    //Address beautification to show in table
    beautifyAddress2(value) {
      let contactAddressArr = [];
      contactAddressArr.push(value.City);
      contactAddressArr.push(value.State);
      contactAddressArr.push(value.Country);
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
    addToMySavedContacts() {
      if (this.selectedInner.length > 0) {
        for (let i = 0; i < this.selectedInner.length; i++) {
          if (!this.selectedInner[i].IsSavedContact) {
            this.addabelContactsCount++;
          }
        }
        if (this.addabelContactsCount > 0) {
          this.selecteContactWarningForAddToMySavedPopup = false;
          this.selecteContactWarningForAddToMySavedPopupMessage = null;
          this.openAddToMySavedContactsCreditPopup = true;
          if (this.addabelContactsCount == 1) {
            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct 1 credit";
          } else {
            this.openAddToMySavedContactsCreditPopupMessage = "This will deduct " + this.addabelContactsCount + " credits";
          }
        } else {
          this.selecteContactWarningForAddToMySavedPopup = true;
          this.selecteContactWarningForAddToMySavedPopupMessage = "Please select not saved contacts to add as My Saved contacts.";
        }
      } else {
        this.selecteContactWarningForAddToMySavedPopup = true;
        this.selecteContactWarningForAddToMySavedPopupMessage = "Please select contacts to add.";
      }
    },
    cancelAddToMySavedContacts() {
      this.addabelContactsCount = 0;
      this.openAddToMySavedContactsCreditPopup = false;
      this.openAddToMySavedContactsCreditPopupMessage = null;
      this.selectedMain = [];
      this.selectedInner = [];
    },
    getContactLimitsForAddToMySavedContacts() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/GetContactLimits")
        .then((response) => {
          let data = response.data;
          if (data.DailyContactLimit == -1) {
                this.addabelContactsCount = 0
                this.openAddToMySavedContactsCreditPopup = false
                this.openAddToMySavedContactsCreditPopupMessage = null
              //  // this.exportContactsForAddToMySavedContacts(this.selectedMain)
                this.listContactSavedTags();
                this.addTagsPopup = true;
                this.tagModuleRequest = 'savecontact';
            //this.exportContactsForAddToMySavedContacts(this.selectedInner);
          } else if (
            this.selectedInner.length <= data.TotalContactLimitRemaining &&
            this.selectedInner.length <= data.DailyContactLimitRemaining
          ) {
                this.addabelContactsCount = 0
                this.openAddToMySavedContactsCreditPopup = false
                this.openAddToMySavedContactsCreditPopupMessage = null
                // this.exportContactsForAddToMySavedContacts(this.selectedMain)
                this.listContactSavedTags();
                this.addTagsPopup = true;
                this.tagModuleRequest = 'savecontact';
            //this.exportContactsForAddToMySavedContacts(this.selectedInner);
          } else {
            this.creditLimitWarningPopup = true;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    exportContactsForAddToMySavedContacts(selectedContacts) {
      let exportContactsParams = [];
      for (let i = 0; i < selectedContacts.length; i++) {
        exportContactsParams.push(selectedContacts[i].Id);
      }
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchContacts/ExportContacts", {
          contactIds: exportContactsParams,
        })
        .then(() => {
          this.cancelAddToMySavedContacts();
          this.addToMySavedContactsSuccessPopup = true;
          this.getContactLimitsOnLoad()
          this.$refs.searchContactsRef.searchPaginationSetTimeOut();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    exportCompanies() {
      if (!this.selectedMain.length > 0) {
        this.selecteCompaniesWarningForExportCompaniesPopup = true;
      } else {
        this.downloadExcelCompaniesPopup = true;
        let arrObj = [];
        for (let i = 0; i < this.selectedMain.length; i++) {
          arrObj.push({
            CompanyId: this.selectedMain[i].CompanyId,
            Logo: this.selectedMain[i].Logo,
            CompanyName: this.selectedMain[i].CompanyName,
            Location: this.selectedMain[i].City + ", " + this.selectedMain[i].State + ", " + this.selectedMain[i].Country,
            "Revenue Range": this.selectedMain[i].RevenueRange,
            Employees: this.selectedMain[i].Employees,
            Industry: this.selectedMain[i].Industry,
            Keywords: this.selectedMain[i].Trigger_Value.replace(/,/g, " | "),
            Score: this.selectedMain[i].Score,
            Url: this.selectedMain[i].Url,
          });
        }
        this.downloadCompanies = [{ name: "Companies", data: arrObj }];
      }
    },
    changeKeywordsMoreLess(value, i) {
      // this.leadsMoreOrLessArr[i] = value;
      Vue.set(this.leadsMoreOrLessArr,i,value);
    },
    addTagToContacts(paramsContactTag){
          this.addTagsPopup = false;
          let contactTags = [];
          let contactIds = [];
          if(paramsContactTag.contactSelectedTags && paramsContactTag.contactSelectedTags.length > 0){
            paramsContactTag.contactSelectedTags.forEach(element =>{
              contactTags.push(element.TagName);
            })
          }
          if(this.companyDetailPopup){
            this.selectedInner.forEach(element => {
              contactIds.push(element.Id);
            });
          } else {
            this.selectedMain.forEach(element => {
              contactIds.push(element.Id);
            });
          }
          let addContactParams = {
            contactIds:contactIds,
            ContactUserDefinedTags:contactTags,
            IsExport:false,
            ContactSavedFrom:this.tagModuleRequest == 'export' || this.tagModuleRequest =='savecontact' || this.tagModuleRequest =='contactdetails'?'shopping':'',
          };
          this.$vs.loading();
          this.axios.post("/ws/FindAnyoneSearchContacts/ExportContacts",addContactParams).then((response) => {
           let data = response.data;
            this.addTagsPopup = false;
            this.$refs.addTagToSelectedContact.selectedTag = [];
            if(this.tagModuleRequest == 'export'){
              data.forEach(element => {
                element.ContactUserDefinedTags = element.ContactUserDefinedTags?element.ContactUserDefinedTags.join():'';
              });
              this.downloadContacts = [{ name: "Contacts", data: data }]
              setTimeout(() => {
                if(this.exportContactType == 'csv'){
                  this.$refs.selectedToCsv.click();
                } else {
                  this.$refs.selectedToExcel.click();
                }
                this.getContactLimitsOnLoad()
               // this.$refs.searchContactsRef.searchPaginationSetTimeOut();
              }, 500);
            }
            if(this.companyDetailPopup){
              this.selectedInner = [];
            } else {
              this.selectedMain = [];
            }
            if(this.tagModuleRequest == 'savecontact'){
              this.getContactLimitsOnLoad()
              // if(!this.companyDetailPopup){
              //   this.searchPaginationSetTimeOut();
              // }else{
              //   this.$refs.searchContactsRef.searchPaginationSetTimeOut();
              // }
              this.addToMySavedContactsSuccessPopup = true
            }
            if(this.tagModuleRequest == 'contactdetails'){
              this.getContactDetail();
            }
            this.updateContactsList(data);
            this.$vs.loading.close();
          }).catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          });         
        },
        listContactSavedTags(tagDetails = null){
          this.$vs.loading();
          this.axios.get("/ws/FindAnyoneSearchContacts/ListContactSavedTagsUserDefined").then((response) => {
            let data = response.data;
            this.selectTagsOptions = data;
            if(this.addTagsPopup && tagDetails){
              let newTag = this.selectTagsOptions.filter((x) => (x.TagName) === tagDetails.tagName);
              if(newTag.length){
                this.$refs.addTagToSelectedContact.selectedTag.push(newTag[0]);
              }
           }
            this.$vs.loading.close();
          }).catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
        },
        deleteUserDefinedTag(tagId){
          this.$vs.loading();
          this.axios.get("/ws/FindAnyoneSearchContacts/DeleteContactSavedTagUserDefined?id="+tagId).then(() => {
            this.listContactSavedTags();
            this.$vs.notify({
              title: "Success",
              text: 'Tag deleted successfully',
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
            this.$vs.loading.close();
          }).catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
        },
        async saveUpdateTag(tagDetails){
          this.$vs.loading();
             let input = {
               "Id": tagDetails.tagId,
               "TagName": tagDetails.tagName,
               "IsShared": tagDetails.isTagShared
             }
             await this.axios
                 .post("/ws/FindAnyoneSearchContacts/SaveContactSavedTagUserDefined", input)
                 .then(() => {
                   this.$refs.addUpdateTag.newTagName = null;
                   this.$refs.addUpdateTag.isTagShared = false;
                   this.$refs.addUpdateTag.tagId = "";
                   this.userDefinedTagDetails = null;
                   this.addUpdateTagPopup = false;
                   this.listContactSavedTags(tagDetails);
                   this.$vs.notify({
                    title: "Success",
                    text: tagDetails.tagId?'Tag updated successfully':'Tag added successfully',
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                  });
                     setTimeout(() => {
                         this.errors.clear();
                     }, 100);
                     this.$vs.loading.close();
                 })
                 .catch(e => {
                     this.$vs.loading.close();
                     this.showError(e);
                     this.$vs.notify({
                         title: "Erorr",
                         text: e.response.data.Errors[0].Message,
                         color: "danger",
                         iconPack: "feather",
                         icon: "icon-check",
                         position: "top-right",
                         time: 4000
                     });
                 });
       },
       editUserDefinedTag(tagDetails){
         this.userDefinedTagDetails = tagDetails;
         this.addUpdateTagPopup = true;
       },
       closeAddUpdateTagPopup(){
         this.userDefinedTagDetails = null;
         this.$refs.addUpdateTag.newTagName = null;
         this.$refs.addUpdateTag.isTagShared = false;
         this.$refs.addUpdateTag.tagId = "";        
         this.addUpdateTagPopup = false;
       },
       addTagToSeleted(){
          let contactSelected = this.companyDetail?this.selectedInner:this.selectedMain;
          if(contactSelected.length){
            this.listContactSavedTags();
            this.addTagsPopup = true;
          } else {
            this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contact to add tags to contact"
            this.selecteContactWarningForAddToMySavedPopup = true;
          }
       },
       removeTagSelected(){
        let contactSelected = this.companyDetail?this.selectedInner:this.selectedMain;
          if(contactSelected.length){
            this.addTagsPopup = true;
            this.listContactSavedTags();
          } else {
            this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contact to remove tags from contact"
            this.selecteContactWarningForAddToMySavedPopup = true;
          }
       },
       removeTagsFromContactConfirmation(removeTags){
          this.addTagsPopup = false;
          this.removeTagPopup = true;
          this.tagRemoveList = removeTags;
       },
       async removeTagsFromContact(){
        this.$vs.loading();
        let contactIds = [];
        let contactTags = [];
        if(this.companyDetailPopup){
          this.selectedInner.forEach(element => {
            contactIds.push(element.Id);
          });
        } else {
          this.selectedMain.forEach(element => {
            contactIds.push(element.Id);
          });
        }
          if(this.tagRemoveList.length > 0){
            this.tagRemoveList.forEach(element =>{
              contactTags.push(element.TagName);
            })
          }
          
        let input = {
          "ContactUserDefinedTags": contactTags,
          "ContactIds": contactIds
        }
        await this.axios
            .post("/ws/FindAnyoneSearchContacts/RemoveTagFromContacts", input)
            .then(() => {
              this.removeTagPopup = false;
              this.tagRemoveList = null;
              this.$refs.addTagToSelectedContact.selectedTag = [];
              if(this.companyDetailPopup){
                this.selectedInner = [];
              } else {
                this.selectedMain = [];
              }
              this.$vs.notify({
               title: "Success",
               text: 'Tags removed form contact successfully',
               color: "success",
               iconPack: "feather",
               icon: "icon-check",
               position: "top-right",
               time: 4000,
             });
                setTimeout(() => {
                    this.errors.clear();
                }, 100);
                this.$vs.loading.close();
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Erorr",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000
                });
            });        
       },
       editTagsForContact(){
          this.addTagsPopup = true;
          if(this.contactDetails.ContactUserDefinedTags.length){
            this.$refs.addTagToSelectedContact.selectedTag = [];
            this.selectTagsOptions.forEach(ele =>{
              if(this.contactDetails.ContactUserDefinedTags.includes(ele.TagName)){
                this.$refs.addTagToSelectedContact.selectedTag.push(ele);
              }
            });
          }
       },
       updateTagsContactDetails(contactUserTags){
        this.addTagsPopup = false;
          let contactTags = [];
          if(contactUserTags.length > 0){
            contactUserTags.forEach(element =>{
              contactTags.push(element.TagName);
            })
          }
          let addContactParams = {
            Contact_Id:this.contactDetails.Id,
            Tag_Ids:contactTags,
          };
          this.$vs.loading();
          this.axios.post("/ws/FindAnyoneSavedContacts/UpdateSavedContactTags",addContactParams).then(() => {
            this.addTagsPopup = false;
            this.$refs.addTagToSelectedContact.selectedTag = [];
            this.getListOfTagsContactDetails();
            this.$vs.loading.close();
          }).catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          });         
       },
       getListOfTagsContactDetails(){
        this.$vs.loading();
        this.axios.get("/ws/FindAnyoneSavedContacts/GetSavedContactTags?id="+this.selectedContactId).then((response) => {
          let data = response.data;
          this.contactDetails.ContactUserDefinedTags = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
              title: "Erorr",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
          });
      });
    },
    checkCreditDeductForExport(selectedContacts){
          let creditDeduct = selectedContacts.filter(contact => contact.IsSavedContact == false);
          if(creditDeduct.length >0){
            this.isCreditDeductionEnable = true;
            this.numberOfCreditMessage = "This will deduct " + creditDeduct.length + " credits";
            this.creditWarningPopup = true;
          } else {
            //this.exportContacts(selectedContacts);
            this.listContactSavedTags();
            this.addTagsPopup = true;
            this.tagModuleRequest = 'export';
          }
    },
    updateContactsList(data){
        let contact;
        if(this.companyDetailPopup){
          contact = this.$refs.searchContactsRef.contacts;
        } else {
          contact = this.contacts;
        }
        let results;
        data.forEach(element => {
          results= contact.filter(ele => ele.Id == element.Id);
        });
        results.forEach(ele => {
          ele.IsSavedContact = true;
        });
    },
  },
};
</script>
